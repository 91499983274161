<template>
  <aside class="sidebar is-show" id="sidebar">
    <div class="sidebar-box">
      <ul class="uk-nav">
        <li :class="{ 'uk-active': isActiveRoute('/') }">
          <router-link to="/"><i class="ico_home"></i><span>Home</span></router-link>
        </li>
        <li class="uk-nav-header">Account</li>
        <li :class="{ 'uk-active': isActiveRoute('/profile') }">
          <router-link to="/profile"><i class="ico_profile"></i><span>Profile</span></router-link>
        </li>
        <li :class="{ 'uk-active': isActiveRoute('/favourites') }">
          <router-link to="/favourites">
            <i class="ico_favourites"></i><span>Favourites</span><span class="count">15</span>
          </router-link>
        </li>
        <li :class="{ 'uk-active': isActiveRoute('/chats') }">
          <router-link to="/chats"><i class="ico_chats"></i><span>Chats</span></router-link>
        </li>
        <li :class="{ 'uk-active': isActiveRoute('/friends') }">
          <router-link to="/friends"><i class="ico_friends"></i><span>Friends</span></router-link>
        </li>
        <li :class="{ 'uk-active': isActiveRoute('/wallet') }">
          <router-link to="/wallet"><i class="ico_wallet"></i><span>Wallet</span></router-link>
        </li>
        <li class="uk-nav-header">Main</li>
        <li :class="{ 'uk-active': isActiveRoute('/games-store') }">
          <router-link to="/games-store"><i class="ico_store"></i><span>Store</span></router-link>
        </li>
        <li :class="{ 'uk-active': isActiveRoute('/market') }">
          <router-link to="/market"><i class="ico_market"></i><span>Market</span></router-link>
        </li>
        <li :class="{ 'uk-active': isActiveRoute('/streams') }">
          <router-link to="/streams"><i class="ico_streams"></i><span>Streams</span></router-link>
        </li>
        <li :class="{ 'uk-active': isActiveRoute('/community') }">
          <router-link to="/community"><i class="ico_community"></i><span>Community</span></router-link>
        </li>
        <li class="uk-nav-header">Support</li>
        <li>
          <a href="#" @click.prevent="openModal('modal-report')">
            <i class="ico_report"></i><span>Report</span>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="openModal('modal-help')">
            <i class="ico_help"></i><span>Help</span>
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
/* global UIkit */
import { useRoute } from 'vue-router';

export default {
  name: 'SidebarComponent',
  setup() {
    const route = useRoute();

    const isActiveRoute = (path) => {
      return route.path === path;
    };

    const openModal = (modalId) => {
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        UIkit.modal(modalElement).show();
      }
    };

    return {
      isActiveRoute,
      openModal,
    };
  },
};
</script>

<style scoped>
/* 如果需要，可以在这里添加组件特定的样式 */
</style>
