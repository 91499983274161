<template>
  <header class="page-header">
    <div class="page-header__inner">
      <div class="page-header__sidebar">
        <div class="page-header__menu-btn">
          <button class="menu-btn ico_menu is-active"></button>
        </div>
        <div class="page-header__logo">
          <img src="/assets/img/logo.png" alt="logo">
          <span class="page-header__logo_text">TEAMHOST</span>
        </div>
      </div>
      <div class="page-header__content">
        <div class="page-header__search">
          <div class="search">
            <div class="search__input">
              <i class="ico_search"></i>
              <input type="search" name="search" placeholder="Search">
            </div>
            <div class="search__btn">
              <button type="button">
                <i class="ico_microphone"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="page-header__action">
          <!-- 使用 router-link 替换 a 标签 -->
          <router-link class="action-btn" to="/chats">
            <i class="ico_message"></i><span></span>
          </router-link>
          <router-link class="action-btn" to="/friends">
            <i class="ico_notification"></i><span></span>
          </router-link>
          <router-link class="profile" to="/wallet">
            <img src="/assets/img/profile.png" alt="profile">
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
};
</script>

<style scoped>
/* 如果需要，可以在这里添加组件特定的样式 */
</style>
