<template>
  <main class="page-main">
    <div class="uk-grid" data-uk-grid>
      <!-- 推荐和特色部分 -->
      <div class="uk-width-2-3@l uk-width-3-3@m uk-width-3-3@s">
        <h3 class="uk-text-lead">Recommended & Featured</h3>
        <div class="js-recommend">
          <div class="swiper">
            <div class="swiper-wrapper">
              <!-- 推荐滑块 -->
              <div class="swiper-slide" v-for="(slide, index) in recommendSlides" :key="index">
                <div class="recommend-slide">
                  <div class="recommend-slide__box">
                    <div class="recommend-slide__info">
                      <div class="recommend-slide__logo">{{ slide.logo }}</div>
                      <div class="recommend-slide__intro">
                        <span>{{ slide.intro }}</span>
                      </div>
                      <div class="recommend-slide__rating-and-price">
                        <div class="recommend-slide__rating">
                          <span>{{ slide.rating }}</span><i class="ico_star"></i>
                        </div>
                        <div class="recommend-slide__price">
                          <span>{{ slide.price }} </span>
                        </div>
                      </div>
                      <div class="recommend-slide__btn">
                        <a :href="slide.link" class="uk-button uk-button-danger">Play Now</a>
                      </div>
                      <div class="recommend-slide__platform">
                        <i class="ico_windows"></i>
                        <i class="ico_apple"></i>
                      </div>
                    </div>
                    <div class="recommend-slide__img">
                      <img :src="slide.image" alt="image">
                    </div>
                    <div class="recommend-slide__favorites">
                      <i class="ico_favourites"></i>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 推荐滑块结束 -->
            </div>
            <div class="swipper-nav">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>

      <!-- Trending Now 部分 -->
      <div class="uk-width-1-3@l uk-width-3-3@m uk-width-3-3@s">
        <h3 class="uk-text-lead">Trending Now</h3>
        <div class="js-trending">
          <div class="swiper">
            <div class="swiper-wrapper">
              <!-- Trending滑块 -->
              <div class="swiper-slide" v-for="(game, index) in trendingGames" :key="index">
                <div class="game-card --horizontal">
                  <div class="game-card__box">
                    <div class="game-card__media">
                      <a :href="game.link">
                        <img :src="game.image" :alt="game.title">
                      </a>
                    </div>
                    <div class="game-card__info">
                      <a class="game-card__title" :href="game.link">{{ game.title }}</a>
                      <div class="game-card__genre">{{ game.genre }}</div>
                      <div class="game-card__rating-and-price">
                        <div class="game-card__rating">
                          <span>{{ game.rating }}</span><i class="ico_star"></i>
                        </div>
                        <div class="game-card__price">
                          <span>{{ game.price }}</span>
                        </div>
                      </div>
                      <div class="game-card__bottom">
                        <div class="game-card__platform">
                          <i class="ico_windows"></i><i class="ico_apple"></i>
                        </div>
                        <div class="game-card__users">
                          <ul class="users-list">
                            <li v-for="(user, idx) in game.users" :key="idx">
                              <img :src="user" alt="user">
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Trending滑块结束 -->
            </div>
            <div class="swipper-nav">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>

      <!-- Most Popular 部分 -->
      <div class="uk-width-1-1">
        <h3 class="uk-text-lead">Most Popular</h3>
        <div class="js-popular">
          <div class="swiper">
            <div class="swiper-wrapper">
              <!-- Most Popular滑块 -->
              <div class="swiper-slide" v-for="(game, index) in popularGames" :key="index">
                <div class="game-card">
                  <div class="game-card__box">
                    <div class="game-card__media">
                      <a :href="game.link">
                        <img :src="game.image" :alt="game.title">
                      </a>
                    </div>
                    <div class="game-card__info">
                      <a class="game-card__title" :href="game.link">{{ game.title }}</a>
                      <div class="game-card__genre">{{ game.genre }}</div>
                      <div class="game-card__rating-and-price">
                        <div class="game-card__rating">
                          <span>{{ game.rating }}</span><i class="ico_star"></i>
                        </div>
                        <div class="game-card__price">
                          <span>{{ game.price }}</span>
                        </div>
                      </div>
                      <div class="game-card__bottom">
                        <div class="game-card__platform">
                          <i class="ico_windows"></i><i class="ico_apple"></i>
                        </div>
                        <div class="game-card__users">
                          <ul class="users-list">
                            <li v-for="(user, idx) in game.users" :key="idx">
                              <img :src="user" alt="user">
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Most Popular滑块结束 -->
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
/* global Swiper */
export default {
  name: 'HomeView',
  data() {
    return {
      recommendSlides: [
        {
          logo: 'Team Host',
          intro: 'Team Host offers a unique single-player experience: fast-paced and violent combat.',
          rating: '4.3',
          price: '$14',
          link: 'index.html',
          image: 'assets/img/recommend-slide-img.png',
        },
        // 您可以添加更多推荐滑块数据
      ],
      trendingGames: [
        {
          title: 'Alien Games',
          genre: 'Warring factions have brought the Origin System to the brink of destruction.',
          rating: '4.5',
          price: 'Free',
          link: '10_game-profile.html',
          image: 'assets/img/trending.jpg',
          users: [
            'assets/img/user-1.png',
            'assets/img/user-2.png',
            'assets/img/user-3.png',
            'assets/img/user-4.png',
          ],
        },
        // 您可以添加更多Trending Now游戏数据
      ],
      popularGames: [
        {
          title: 'Solitary Crusade',
          genre: 'Shooter / Platformer',
          rating: '4.8',
          price: '$4.99',
          link: '10_game-profile.html',
          image: 'assets/img/game-1.jpg',
          users: [
            'assets/img/user-1.png',
            'assets/img/user-2.png',
            'assets/img/user-3.png',
            'assets/img/user-4.png',
          ],
        },
        // 您可以添加更多Most Popular游戏数据
      ],
    };
  },
  mounted() {
    // 初始化 Swiper
    if (window.Swiper) {
      new Swiper('.js-recommend .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: '.js-recommend .swiper-button-next',
          prevEl: '.js-recommend .swiper-button-prev',
        },
        pagination: {
          el: '.js-recommend .swiper-pagination',
          clickable: true,
        },
      });

      new Swiper('.js-trending .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: '.js-trending .swiper-button-next',
          prevEl: '.js-trending .swiper-button-prev',
        },
        pagination: {
          el: '.js-trending .swiper-pagination',
          clickable: true,
        },
      });

      new Swiper('.js-popular .swiper', {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: '.js-popular .swiper-button-next',
          prevEl: '.js-popular .swiper-button-prev',
        },
        pagination: {
          el: '.js-popular .swiper-pagination',
          clickable: true,
        },
      });
    } else {
      console.error('Swiper is not defined. Please ensure it is loaded.');
    }
  },
};
</script>

<style scoped>
/* 如果需要，可以在这里添加组件特定的样式 */
</style>
