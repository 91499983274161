import { createRouter, createWebHistory } from 'vue-router';

// 导入您的视图组件
import HomeView from '../views/HomeView.vue';
import ProfileView from '../views/ProfileView.vue';
import FavouritesView from '../views/FavouritesView.vue';
import WalletView from '../views/WalletView.vue';
import GamesStoreView from '../views/GamesStoreView.vue';
import GameProfileView from '../views/GameProfileView.vue';
import MarketView from '../views/MarketView.vue';
import StreamsView from '../views/StreamsView.vue';
import CommunityView from '../views/CommunityView.vue';
import FriendsView from '../views/FriendsView.vue';
import ChatsView from '../views/ChatsView.vue';
// ... 导入其他视图组件

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
  },
  {
    path: '/favourites',
    name: 'Favourites',
    component: FavouritesView,
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: WalletView,
  },
  {
    path: '/games-store',
    name: 'GamesStore',
    component: GamesStoreView,
  },
  {
    path: '/game-profile',
    name: 'GameProfile',
    component: GameProfileView,
  },
  {
    path: '/market',
    name: 'Market',
    component: MarketView,
  },
  {
    path: '/streams',
    name: 'Streams',
    component: StreamsView,
  },
  {
    path: '/community',
    name: 'Community',
    component: CommunityView,
  },
  {
    path: '/friends',
    name: 'Friends',
    component: FriendsView,
  },
  {
    path: '/chats',
    name: 'Chats',
    component: ChatsView,
  },
  // ... 添加其他路由
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
